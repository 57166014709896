import React from 'react';

const defaultState = {
  dark: false,
  toggleDark: () => {},
};

const UserLocationContext = React.createContext(defaultState);

class UserLocationProvider extends React.Component {
  state = {
    latitude: null,
    longitude: null,
  };

  setUserLocation = ({ latitude, longitude }) => {
    this.setState({
      latitude,
      longitude,
    });
  };

  render() {
    const { children } = this.props;
    const { latitude, longitude } = this.state;
    return (
      <UserLocationContext.Provider
        value={{
          latitude,
          longitude,
          setUserLocation: this.setUserLocation,
        }}
      >
        {children}
      </UserLocationContext.Provider>
    );
  }
}

export default UserLocationContext;

export { UserLocationProvider };
