export const Colors = {
  RED: '#ED3B4F',
  BLACK: '#111',
  GREY: '#909090',
  LIGHT_GREY: '#FAFAFA',
  MEDIUM_GREY: '#F5F5F5',
  ORANGE: '#F7893E',
  GREEN: 'green',
};

export default {
  colors: {
    RED: Colors.RED,
    BLACK: Colors.BLACK,
    GREY: Colors.GREY,
    LIGHT_GREY: Colors.LIGHT_GREY,
    MEDIUM_GREY: Colors.MEDIUM_GREY,
    ORANGE: Colors.ORANGE,
    GREEN: Colors.GREEN,
  },
  fonts: {
    body: 'Roboto',
    heading: 'Roboto',
  },
  buttons: {
    primary: {
      color: '#fff',
      backgroundColor: Colors.RED,
      borderRadius: 4,
      cursor: 'pointer',
      boxShadow: '0 4px 6px rgba(50,50,93,.11), 0 2px 6px rgba(0,0,0,.08)',
    },
    secondary: {
      color: Colors.RED,
      backgroundColor: '#fff',
      borderRadius: 4,
      cursor: 'pointer',
      boxShadow: '0 4px 6px rgba(50,50,93,.11), 0 2px 6px rgba(0,0,0,.08)',
    },
    jumbo: {
      color: Colors.RED,
      backgroundColor: '#fff',
      borderRadius: 4,
      cursor: 'pointer',
      height: 60,
      boxShadow: '0 4px 6px rgba(50,50,93,.11), 0 2px 6px rgba(0,0,0,.08)',
    },
    outline: {
      color: Colors.RED,
      backgroundColor: '#fff',
      boxShadow: `0px 0px 0px 2px ${Colors.RED} inset`,
      cursor: 'pointer',
    },
    outlineSecondary: {
      color: '#fff',
      backgroundColor: 'transparent',
      boxShadow: '0px 0px 0px 2px #fff inset',
      cursor: 'pointer',
    },
  },
};
