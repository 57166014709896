// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-category-js": () => import("./../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-menu-js": () => import("./../src/templates/menu.js" /* webpackChunkName: "component---src-templates-menu-js" */),
  "component---src-templates-restaurant-js": () => import("./../src/templates/restaurant.js" /* webpackChunkName: "component---src-templates-restaurant-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-esplora-js": () => import("./../src/pages/esplora.js" /* webpackChunkName: "component---src-pages-esplora-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-ristoranti-js": () => import("./../src/pages/ristoranti.js" /* webpackChunkName: "component---src-pages-ristoranti-js" */),
  "component---src-pages-search-js": () => import("./../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */)
}

