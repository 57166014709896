/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import React from 'react';
import { ApolloProvider } from 'react-apollo';
import { ThemeProvider } from 'emotion-theming';
import { client } from './src/apolloClient';
import theme from './src/theme';
import { UserLocationProvider } from './src/context/UserLocationContext';

export const wrapRootElement = ({ element }) => (
  <UserLocationProvider>
    <ThemeProvider theme={theme}>
      <ApolloProvider client={client}>{element}</ApolloProvider>
    </ThemeProvider>
  </UserLocationProvider>
);
